import { Typography, Button } from '@aiops/styleguide';
import "./MicrosolutionCard.scss";
import { navigateToUrl } from 'single-spa';

/**
 * Type for props of MicroserviceCard component.
 */
export type MicrosolutionCardProps = {
  appName: string,
  path: string,
};

/**
 * Renders a card with the name of a microsolution, that links to it (if it's
 * active; otherwise no link).
 */
const MicrosolutionCard = ({
  appName,
  path,
}: MicrosolutionCardProps) => {
  return (
    <Button data-testid="microsolution-card" className='col microsolution-card' onClick={() => navigateToUrl(path)}>
      <Typography variant="paragraph1">
        {appName}
      </Typography>
    </Button >
  );
};

export default MicrosolutionCard;
