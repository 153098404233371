import "./GroupingCard.scss";
import { Typography, Button } from '@aiops/styleguide';

/**
 * Enum of all possible grouping values.
 */
export type Grouping =
  'SOURCE_TO_PAY'
  | 'DATA_MANAGEMENT'
  | 'FINANCE_OPERATIONS'
  | 'BILLING_OPERATIONS'
  | 'HUMAN_RESOURCES';

/**
 * Type for props of GroupingCard component.
 * 
 * @param title
 * The name of the grouping.
 * 
 * @param grouping
 * The unique enum value of the grouping.
 * 
 * @param active
 * Boolean that is true when this grouping is selected.
 * 
 * @param onClick
 * Function that runs when the card is clicked. Takes the card's unique grouping
 * enum value as an argument.
 */
export type GroupingCardProps = {
  groupingName: string,
  groupingId: Grouping,
  active: boolean,
  onClick: (arg: Grouping) => void,
};

/**
 * Renders a clickable card with the title of a groupingn of microsolutions.
 */
const GroupingCard = ({
  groupingName,
  groupingId,
  active,
  onClick,
}: GroupingCardProps) => {

  /**
   * Returns the string with a line break between the first two words, or the
   * string unaltered if it has fewer than two words (where 'words' means 
   * substrings separated by whitespace).
   */
  const formattedTitle = (str: string) => {
    const asList = str.split(' ');
    if (asList.length < 2) {
      return str;
    }
    const firstTwo = `${asList[0]}\n${asList[1]}`;
    if (asList.length === 2) {
      return firstTwo;
    }
    return [firstTwo, ...asList.slice(2)].join(' ');
  }

  return (
    <Button
      data-testid={`grouping-card`}
      className={`grouping-card ${active ? 'active' : 'inactive'}`}
      onClick={() => onClick(groupingId)}
    >
      <Typography variant={`heading7-${active ? "semibold" : "light"}`}>
        {formattedTitle(groupingName)}
      </Typography>
    </Button>
  );
};

export default GroupingCard;
