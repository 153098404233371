import { useState, useEffect } from "react";
import "./AppPicker.scss";
import { AppContainerWithSidePanel, Typography, useAuth } from "@aiops/styleguide";
import MicrosolutionPicker from "../MicrosolutionPicker";
import GroupingPicker from "../GroupingPicker";
import { Grouping } from '../GroupingCard';
import { MicrosolutionCardProps } from "../MicrosolutionCard";

/**
 * Object that describes a Grouping of microsolutions.
 * 
 * @param title
 * The name of the grouping.
 * 
 * @param grouping
 * The unique enum value for this grouping.
 * 
 * @param apps 
 * List of microsolutions within this grouping.
 */
export type GroupingObject = {
  groupingName: string,
  groupingId: Grouping,
  apps: MicrosolutionCardProps[],
}

/**
 * Type for props of AppPicker component.
 * 
 * @param groupings
 * List of GroupingObjects that this user can access.
 */
export type AppPickerProps = {
  groupings: GroupingObject[],
};

/**
 * Renders a side panel with a GroupingPicker component and a
 * MicrosolutionsPicker populated with the active grouping's microsolution
 * tiles.
 */
const AppPicker = ({
  groupings,
}: AppPickerProps) => {
  const [activeGrouping, setActiveGrouping] = useState<Grouping>(groupings[0].groupingId)
  const [firstName, setFirstName] = useState<string>("");

  const { getUser } = useAuth();

  useEffect(() => {
    getUser().then(user => {
      setFirstName(user?.firstName || "");
    })
  }, [])

  return (
    <AppContainerWithSidePanel
      defaultOpen={true}
      sidePanelContents={
        <GroupingPicker
          groupings={groupings.map((g) => ({
            ...g,
            active: g.groupingId === activeGrouping,
            onClick: setActiveGrouping,
          }))}
        />
      }
    >
      <Typography variant="heading2-titles">
        {`Hello ${firstName}`}
      </Typography>
      <Typography
        variant="heading4-light"
        sx={{
          margin: '10px 0px 30px 0px'
        }}
      >
        What would you like to work on today?
      </Typography>
      <MicrosolutionPicker
        microsolutions={groupings.find((g) => g.groupingId === activeGrouping).apps}
      />
    </AppContainerWithSidePanel>
  );
};

export default AppPicker;
