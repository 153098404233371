import {
  AppWrapper,
  AuthProvider,
  AuthCheckWrap,
  AuthContextType,
  CircularProgress,
} from "@aiops/styleguide";
import AppPicker from "./components/AppPicker";
import NoAccess from "./components/NoAccess";
import { navigateToUrl } from "single-spa";

/**
 * Returns the AppPicker app if the user has access to more than one
 * microsolution. If not, forwards the user to the microsolution that they
 * do have access to and returns null.
 * 
 * @param auth 
 * The object returned from the useAuth() hook from AuthProvider.
 */
export const renderWithAuthCheck = (auth: AuthContextType): JSX.Element | null => {

  const { visibleApps, visibleAppsByGroup } = auth?.permissions || {};

  // Handle different possible states for the app list:
  if (!Array.isArray(visibleApps)) {
    // When useAuth is called for the first time and is effectively an empty
    // object, apps can be undefined.
    return (
      <div className='col'>
        <CircularProgress />
      </div>
    );
  } else if (visibleApps.length === 0) {
    // If user doesn't have access to any apps, tell them so.
    return <NoAccess />
  } else if (visibleApps.length === 1) {
    // If the user has access to exactly one app, immediately forward them
    // there (checking that a path exists)
    const path = visibleApps[0].path;
    if (path) {
      navigateToUrl(path);
      return (
        <div>
          <CircularProgress />
        </div>
      );
    }
    throw new Error(`App does not contain path: ${visibleApps[0]}`);
  }

  // In this case the user has access to multiple apps, so show the app picker.
  return (
    <AppPicker
      groupings={visibleAppsByGroup}
    />
  );
}

export default function Root() {

  // This is factored out into a separate function to make it much easeir to test.
  const renderWithAuthCheckWrap = (auth: AuthContextType): JSX.Element | null => {
    return renderWithAuthCheck(auth);
  }

  return (
    <AppWrapper>
      <AuthProvider
        // All users have access to the app picker app, regardless of their
        // permissions.
        checkPermissions={() => true}
      >
        <AuthCheckWrap
          renderWithAuthCheck={renderWithAuthCheckWrap}
        />
      </AuthProvider>
    </AppWrapper>
  );
}
