import "./MicrosolutionPicker.scss";
import MicrosolutionCard, { MicrosolutionCardProps } from "../MicrosolutionCard";

/**
 * Type for props of MicroservicePicker component.
 * 
 * @param microsolutions
 * List of MicrosolutionCardProps objects.
 */
export type MicrosolutionPickerProps = {
  microsolutions: MicrosolutionCardProps[],
};

/**
 * Renders row(s) of MicrosolutionCards.
 */
const MicrosolutionPicker = ({ microsolutions }: MicrosolutionPickerProps) => {

  return (
    <div className='row microsolution-picker'>
      {microsolutions.map((m, idx) => <MicrosolutionCard
        key={idx}
        appName={m.appName}
        path={m.path}
      />)}
    </div>
  );
};

export default MicrosolutionPicker;
