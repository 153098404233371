import "./GroupingPicker.scss";
import GroupingCard, { GroupingCardProps } from "../GroupingCard";

/**
 * Type for props of GroupingPicker component.
 * 
 * @param groupings
 * List of groupings for which to render cards.
 */
export type GroupingPickerProps = {
  groupings: GroupingCardProps[],
};

/**
 * Renders a list of grouping cards, each of which can be clicked.
 */
const GroupingPicker = ({ groupings }: GroupingPickerProps) => {

  return (
    <div className="col grouping-picker">
      {groupings.map((g, idx) => <GroupingCard
        key={idx}
        groupingName={g.groupingName}
        active={g.active}
        groupingId={g.groupingId}
        onClick={g.onClick}
      />)}
    </div>
  );
};

export default GroupingPicker;
