import { Typography } from "@aiops/styleguide";
import "./NoAccess.scss";

/**
 * Renders a component that tells the user that they're signed in but do not have
 * access to any microsolutions.
 */
const NoAccess = () => {
  return (
    <div className="col">
      <Typography variant="heading3">
        You are signed in but do not have access to any microsolutions.
      </Typography>
    </div>
  );
};

export default NoAccess;
